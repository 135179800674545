import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import Layout from "../layout/Layout"
import Pagination from "../components/blog-posts/Pagination"

import { CardHorizontalBlog, CardVerticalBlog } from "../components/Cards"

import useBlogPost from "../utils/useBlogPost"

export default function BlogPreview({ pageContext }) {
  const { numPages, currentPage } = pageContext
  const data = useBlogPost()
  // console.log(data.map((i) => i.tags));
  const tags = data.map(i => i.tags)
  console.log(tags)

  return (
    <Layout>
      <div className="max-w-7xl mx-auto">
        <p className="text-center mobile-title lg:desktop-title lg:pb-0 pt-8">
          The Juicy Pause Blog
        </p>
        <p className="mobile-paragraph lg:desktop-paragraph text-center max-w-sm mx-auto lg:max-w-5xl place-items-center py-8">
          The written word is vital to my creative life. I value literature,
          science, playwriting, songwriting and wisdom philosophy. This section
          is my playground for turning learning into expression, by sharing
          inspirational musings on a myriad of subjects.
        </p>
      </div>
      <div className="max-w-7xl pb-24 mx-auto grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-8">
        {data.map(i => (
          <div className="flex">
            <div className="xl:hidden grid mx-auto z-20 place-items-center">
              <Link to={`/blog/${i.slug}`}>
                <CardVerticalBlog
                  title={i.title}
                  date={i.date}
                  tags={i.tags}
                  desc={i.desc}
                  image={i.image}
                />
              </Link>
            </div>

            <div className="hidden xl:grid lg:grid-cols-2 lg:items-center gap-16">
              <Link to={`/blog/${i.slug}`}>
                <CardHorizontalBlog
                  title={i.title}
                  date={i.date}
                  tags={i.tags}
                  desc={i.desc}
                  image={i.image}
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="max-w-lg mx-auto py-4">
        <Pagination numPages={numPages} currentPage={currentPage} />
      </div>
      <div className="absolute top-16 lg:top-1/4 left-0 z-0">
        <StaticImage
          height={323}
          src="../images/BackgroundElements/Blog/Banano-01.png"
          placeholder="tracedSVG"
          alt="banana"
          className="lg:scale-150 z-0"
        />
      </div>
    </Layout>
  )
}
