import React from "react";
import { Link } from "gatsby";
import { IconArrowLeft, IconArrowRight } from "../../atoms/Icons";

const Pagination = ({ numPages, currentPage }) => {
  var pageArray = [];
  for (var i = 1; i <= numPages; i++) pageArray[i] = i;
  return (
    <div className="navbar  border-2 border-transparent w-auto mx-auto pb-4">
      <ul className="flex justify-center gap-8">
        {currentPage !== 1 && (
          <li>
            <Link
              to={currentPage === 2 ? "/blog" : `/blog/${currentPage - 1}`}
              className="flex items-centers"
            >
              <IconArrowLeft /> Previous
            </Link>
          </li>
        )}
        {pageArray.map((pageNum) => (
          <li key={`pageNum_${pageNum}`}>
            <Link to={pageNum === 1 ? "/blog" : `/blog/${pageNum}`}>
              {pageNum}
            </Link>
          </li>
        ))}
        {currentPage !== numPages && (
          <li>
            <Link
              to={`/blog/${currentPage + 1}`}
              className="flex items-centers"
            >
              Next <IconArrowRight />
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
